import React from "react";
import {useTranslation} from "react-i18next";

export const ValidationMessage = ({error}: any) => {
    const {t} = useTranslation();

    if (typeof error.message === 'string') {
        return (
            <div className="text-red-400 text-sm">{t(error.message)}</div>
        )
    } else {
        return (
            <div className="text-red-400 text-sm">{t(error.message.msg, error.message.params)}</div>
        )
    }
}
