import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface WorkingCurrentlySliceState {
    projectsWorkingTimes: any[];
}

const initialState: WorkingCurrentlySliceState = {
    projectsWorkingTimes: []
};

const storageKey = `${process.env.REACT_APP_STORAGE_KEY}-projectsWorkingTimes`;

const workingCurrentlySlice = createSlice({
    name: 'workingCurrently',
    initialState: initialState,
    reducers: {
        start(state, action: PayloadAction<any>) {
            const currentForProjectIndex = state.projectsWorkingTimes.findIndex(p => p.projectId === action.payload.projectId);
            if (currentForProjectIndex < 0) {
                state.projectsWorkingTimes.push(action.payload);
            } else {
                state.projectsWorkingTimes[currentForProjectIndex] = action.payload;
            }

            localStorage.setItem(storageKey, JSON.stringify(state.projectsWorkingTimes));
        },
        finish(state, action: PayloadAction<any>) {
            const currentForProjectIndex = state.projectsWorkingTimes.findIndex(p => p.projectId === action.payload.projectId);
            state.projectsWorkingTimes[currentForProjectIndex].toDate = action.payload.toDate;

            localStorage.setItem(storageKey, JSON.stringify(state.projectsWorkingTimes));
        },
        remove(state, action: PayloadAction<any>) {
            const currentForProjectIndex = state.projectsWorkingTimes.findIndex(p => p.projectId === action.payload.projectId);
            state.projectsWorkingTimes.splice(currentForProjectIndex, 1);

            localStorage.setItem(storageKey, JSON.stringify(state.projectsWorkingTimes));
        },
        reloadFromCache(state) {
            const storageData = localStorage.getItem(storageKey);
            if (storageData != null) {
                state.projectsWorkingTimes = [...JSON.parse(storageData)];
            }
        }
    },
});

export const workingCurrentlyActions = workingCurrentlySlice.actions;

export default workingCurrentlySlice.reducer;
