import {Menu, Transition} from "@headlessui/react";
import {HiOutlineChevronDown, HiOutlineFlag} from "react-icons/hi";
import React, {Fragment} from "react";
import {useAppDispatch, useAppSelector} from "../store/redux/hooks/reduxHooks";
import {configActions} from "../store/redux/config";

const languages = [
    {name: 'PL', value: 'pl'},
    {name: 'EN', value: 'en'},
];

export const LanguageDropdown = () => {
    const dispatch = useAppDispatch();
    const currentLanguage = useAppSelector(state => state.config.currentLanguage);

    const handleLanguageChange = (language: any) => {
        dispatch(configActions.changeLanguage(language));
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="dropdown-button">
                    <HiOutlineFlag
                        className="mr-2 h-5 w-5 text-gray-500 hover:text-gray-700"
                        aria-hidden="true"
                    />
                    {currentLanguage}

                    <HiOutlineChevronDown
                        className="ml-2 -mr-1 h-5 w-5 text-gray-500 hover:text-gray-700 hidden sm:block"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="dropdown-item w-20">
                    <div className="px-1 py-1 ">
                        {languages.map((language: any) => (
                            <Menu.Item key={language.value}>
                                {({active}) => (
                                    <button onClick={() => handleLanguageChange(language.value)}
                                            className={`${
                                                active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                            } group dropdown-item-button`}
                                    >
                                        {language.name}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
