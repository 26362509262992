import {configureStore} from '@reduxjs/toolkit';

import authReducer from './redux/auth';
import configReducer from './redux/config';
import layoutReducer from './redux/layout';
import workingCurrentlyReducer from './redux/workingCurrently';

// Konfiguracja store
const store = configureStore({
    reducer: {
        auth: authReducer,
        config: configReducer,
        layout: layoutReducer,
        workingCurrently: workingCurrentlyReducer,
    },
});
export default store;

// Określenie typów dla RootState i AppDispatch na podstawie store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
