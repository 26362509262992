import React, {useEffect} from "react";

import axios from "axios";
import {authActions} from "../store/redux/auth";
import {useAppDispatch, useAppSelector} from "../store/redux/hooks/reduxHooks";
import {useNavigate} from "react-router-dom";
import * as _ from 'lodash';
import {DateTime} from "luxon";
import {Credentials} from "../shared/models";
import {useTranslation} from "react-i18next";
import {configActions} from "../store/redux/config";

// Czy pierwsze odpalenie aplikacji
let isInit = true;

export const CoreWrapper = ({children}: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {i18n} = useTranslation();
    const currentLanguage = useAppSelector(state => state.config.currentLanguage);

    axios.interceptors.request.use(
        async config => {
            let authHeader = null;
            const storageData = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`);
            if (storageData != null) {
                const currentUser = JSON.parse(storageData);
                authHeader = {'Authorization': `Bearer ${currentUser.token.accessToken}`};
            }

            config.headers = {
                ...authHeader ?? {},
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'App-Language': currentLanguage,
                ...config.headers
            }

            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    axios.interceptors.response.use(
        async (res) => {
            return res;
        },
        async (error) => {
            if (!_.isNil(error.response)) {
                const status = error.response.status;

                if (error.response.config.url.toString().includes('login')) {
                    return Promise.reject(error);
                }

                if (status === 401) {
                    // Clear from storage
                    dispatch(authActions.logout());
                    navigate('/login', {replace: true});
                }
            }

            return Promise.reject(error);
        }
    );

    // Wczytanie usera z localstorage przy starcie aplikacji
    useEffect(() => {
        const bootstrapAsync = () => {
            let currentUser: Credentials | null = null;

            try {
                const storageData = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`);
                if (!_.isNil(storageData)) {
                    currentUser = JSON.parse(storageData);
                }
            } catch (e) {
                return;
            }

            if (currentUser == null) {
                return;
            } else {
                // Sprawdzenie, czy token jest ok na podstawie daty ważności tokenu
                const tokenExpireDate = DateTime.fromISO(currentUser.token.expiresAt);
                const now = DateTime.now();
                if (now > tokenExpireDate) {
                    return;
                }
            }

            // Ustawienie token
            dispatch(authActions.restoreUser(currentUser));
        };

        bootstrapAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Zmiana języka w aplikacji
    useEffect(() => {
        const changeLanguage = async (lang: any) => {
            await i18n.changeLanguage(lang);
        }

        if (isInit) {
            isInit = false;
            dispatch(configActions.loadCurrentLanguageFromLocalStore());
        } else {
            changeLanguage(currentLanguage).then(r => {
                console.log('Language changed: ' + currentLanguage)
            });
        }
    }, [currentLanguage, dispatch, i18n]);

    return (
        <>{children}</>
    )
}
