import React from 'react';
import {Outlet} from "react-router-dom";

export const ErrorLayout = () => {
    return (
        <div className="min-h-screen">
            <main>
                <Outlet/>
            </main>
        </div>
    );
}
