import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import plTranslations from '../lang/pl.json';
import enTranslations from '../lang/en.json';

// the translations
const resources = {
    "pl": {
        translation: plTranslations
    },
    "en": {
        translation: enTranslations
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "pl",
        fallbackLng: "pl",
        keySeparator: '.', // '.' jeżeli wielopoziomy json; false, jeżeli nie
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
