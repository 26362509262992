export const Loader = () => {
    return (
        <div className="py-10">
            <div className="flex justify-center">
                <div className="sk-chase">
                    <div className="sk-chase-dot before:bg-indigo-500" />
                    <div className="sk-chase-dot before:bg-indigo-500" />
                    <div className="sk-chase-dot before:bg-indigo-500" />
                    <div className="sk-chase-dot before:bg-indigo-500" />
                    <div className="sk-chase-dot before:bg-indigo-500" />
                    <div className="sk-chase-dot before:bg-indigo-500" />
                </div>
            </div>
        </div>
    )
}
