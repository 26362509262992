import React, {PropsWithChildren} from 'react';

export const ContentWrapper = ({children}: PropsWithChildren<any>) => {
    return (
        <div className="pb-4">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="bg-white overflow-auto shadow-sm sm:rounded-lg">
                    {children}
                </div>
            </div>
        </div>
    );
}
