import React, {Suspense} from 'react';
import {ToastContainer} from "react-toastify";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {FullScreenLoader} from "./components";
import {CoreWrapper} from "./core";
import {ErrorLayout} from "./layouts/error";
import {BlankLayout} from "./layouts/blank";

const Login = React.lazy(() => import('./pages/auth/Login'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const Page404 = React.lazy(() => import('./pages/errors/Page404'));
const Page500 = React.lazy(() => import('./pages/errors/Page500'));

// Dashboard musi być jako lazy load, inaczej nie ładuje currentUser z local storage przy refresh
const DashboardRouting = React.lazy(() => import('./pages/dashboard/DashboardRouting'));

const queryClient = new QueryClient();

const registerEnabled = process.env.REACT_APP_IS_REGISTER_ENABLED === 'true';

const App = () => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <CoreWrapper>
                        <Suspense fallback={<FullScreenLoader/>}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/dashboard"/>}/>
                                {/*<Route path="/" element={<LandingPageLayout />}>*/}
                                {/*    <Route index element={<LandingPage/>}/>*/}
                                {/*    <Route path="/features" element={<Features/>}/>*/}
                                {/*    <Route path="/product" element={<Product/>}/>*/}
                                {/*</Route>*/}

                                <Route path="/" element={<BlankLayout/>}>
                                    <Route path="/login" element={<Login/>}/>
                                    {registerEnabled && (<Route path="/register" element={<Register/>}/>)}
                                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                                    <Route path="/reset-password" element={<ResetPassword/>}/>
                                </Route>

                                <Route path={'/error/*'} element={<ErrorLayout/>}>
                                    <Route path="404" element={<Page404/>}/>
                                    <Route path="500" element={<Page500/>}/>
                                </Route>


                                <Route path="/dashboard/*" element={
                                    <Suspense fallback={<FullScreenLoader/>}>
                                        <DashboardRouting/>
                                    </Suspense>
                                }/>

                                <Route path="*" element={<Page404/>}/>
                            </Routes>
                        </Suspense>
                    </CoreWrapper>
                </BrowserRouter>

                {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false}/>}
            </QueryClientProvider>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={"dark"}
            />
        </>
    );
}

export default App;
