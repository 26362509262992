import React from 'react';
import logo from '../assets/brand/logo2.png';
import logoSmall from '../assets/brand/logo3.png';

export const AppLogo = ({className, alt, isSmall}: any) => {
    return (
        <img
            className={className}
            src={isSmall ? logoSmall : logo}
            alt={alt}
        />
    );
}
