import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Credentials} from "../../shared/models/Auth";

interface AuthSliceState {
    currentUser: Credentials | null;
    isLoading: boolean;
}

const initialState: AuthSliceState = {
    currentUser: null,
    isLoading: false,
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        login(state, action: PayloadAction<any>) {
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`, JSON.stringify(action.payload));
            state.currentUser = action.payload;
        },
        logout(state) {
            localStorage.removeItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`);
            state.currentUser = null;
        },
        register(state, action: PayloadAction<any>) {
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`, JSON.stringify(action.payload));
            state.currentUser = action.payload;
        },
        restoreUser(state, action: PayloadAction<any>) {
            state.currentUser = action.payload;
        },
        updateCurrentUserData(state, action: PayloadAction<any>) {
            if (state.currentUser !== null) {
                const user: Credentials = {...state.currentUser};
                user.name = action.payload.name ?? user.name;
                user.email = action.payload.email ?? user.email;
                user.avatarUrl = action.payload.avatarUrl ?? user.avatarUrl;
                state.currentUser = user;
                localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`, JSON.stringify(user));
            }
        },
        updateAvatarUrl(state, action: PayloadAction<any>) {
            if (state.currentUser !== null) {
                const user: Credentials = {...state.currentUser};
                user.avatarUrl = action.payload.avatarUrl;
                state.currentUser = user;
                localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}-currentUser`, JSON.stringify(user));
            }
        }
    },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
