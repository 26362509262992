import React from 'react';
import {useTranslation} from "react-i18next";
import * as _ from 'lodash';
import {DateTime} from "luxon";

export const ModelTimestamps = ({createdAt, updatedAt, deletedAt}: { createdAt: string, updatedAt: string, deletedAt: string }) => {
    const {t} = useTranslation();

    return (
        <>
            {!_.isNil(createdAt) && <p className="text-sm font-light text-gray-500">
                <strong>{t('global.createdAt')}</strong>: {DateTime.fromISO(createdAt).toFormat('yyyy-MM-dd HH:mm')}</p>}
            {!_.isNil(updatedAt) && <p className="text-sm font-light text-gray-500">
                <strong>{t('global.updatedAt')}</strong>: {DateTime.fromISO(updatedAt).toFormat('yyyy-MM-dd HH:mm')}</p>}
            {!_.isNil(deletedAt) && <p className="text-sm font-light text-gray-500">
                <strong>{t('global.deletedAt')}</strong>: {DateTime.fromISO(deletedAt).toFormat('yyyy-MM-dd HH:mm')}</p>}
        </>
    );
}
