import React from 'react';

export const PageHeading = ({title, actionButtons}: any) => {
    return (
        <div className="py-4">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="flex justify-between items-center bg-white overflow-hidden shadow-sm sm:rounded-lg">
                    <div className="p-4 text-lg bg-white">{title}</div>

                    <div className="p-4 space-x-2">{actionButtons}</div>
                </div>
            </div>
        </div>
    );
}
