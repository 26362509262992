import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ConfigSliceState {
    currentLanguage: string;
}

const initialState: ConfigSliceState = {
    currentLanguage: 'pl'
};

const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {
        changeLanguage(state, action: PayloadAction<any>) {
            state.currentLanguage = action.payload;
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}-currentLanguage`, action.payload);
        },
        loadCurrentLanguageFromLocalStore(state) {
            const currentLang = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}-currentLanguage`);
            if (currentLang !== null) {
                state.currentLanguage = currentLang;
            } else {
                state.currentLanguage = process.env.REACT_APP_DEFAULT_LANG ?? 'pl';
            }
        }
    },
});

export const configActions = configSlice.actions;

export default configSlice.reducer;
